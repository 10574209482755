<template>
  <v-container
    id="pricing"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row
      align="center"
      class="text-center"
      justify="center"
    >
      <v-col
        class="mb-0"
        cols="12"
      >
        <pages-heading class="mb-1">
          Choose which product is right for you
        </pages-heading>
      </v-col>

      <template v-for="(plan, i) in plans">
        <v-col
          :key="i"
          class="d-flex"
          cols="12"
          sm="6"
          md="3"
        >
          <pages-plan-card :plan="plan" />
        </v-col>

        <v-col
          v-if="(i === 1 && $vuetify.breakpoint.smAndDown) && i + 1 !== plans.length"
          :key="`divider-${i}`"
          class="pa-6"
          cols="12"
        >
          <v-divider />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesPricing',

    components: {
      PagesHeading: () => import('./components/Heading'),
      PagesPlanCard: () => import('./components/PlanCard'),
    },

    data: () => ({
      plans: [
        {
          best: false,
          heading: 'Home',
          icon: 'mdi-sofa',
          title: 'Comming Soon',
          text: 'Protect the contents of your home.',
        },
        {
          best: true,
          heading: 'Term Life',
          icon: 'mdi-account',
          title: 'Best Price',
          text: 'Protect your family with a term life policy',
          link: 'termlife',
        },
        {
          best: false,
          heading: 'Pets',
          icon: 'mdi-paw',
          title: 'Comming Soon',
          text: 'Cover your pet family with Blanket.',
        },
      ],
    }),
  }
</script>
